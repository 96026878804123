<template lang="html">
  <div
    :class="[{'stripe': stripe, 'hoverFlat': hoverFlat}, `vs-table-${color}`,]"
    class="vs-component vs-con-table">
    <!-- header -->
    <header class="header-table vs-table--header flex flex-wrap">
      <slot name="header"></slot>
      <div
        v-if="search"
        class="con-input-search vs-table--search">
        <input
          v-model="searchx"
          class="input-search vs-table--search-input"
          type="text">
        <vs-icon icon="search"></vs-icon>
      </div>
    </header>
    <slot name="tabs"></slot>
    <div class="con-tablex vs-table--content">
      <div
        :style="styleConTbody"
        class="vs-con-tbody vs-table--tbody ">
        <table
          ref="table"
          class="vs-table vs-table--tbody-table">
          <thead
            ref="thead"
            class="vs-table--thead">
            <tr>
              <th
                v-if="multiple"
                class="td-check">
                <span
                  v-if="multiple"
                  class="con-td-check">
                  <vs-checkbox
                    :icon="isCheckedLine ? 'remove' : 'check'"
                    :checked="isCheckedMultiple"
                    size="small"
                    @click="changeCheckedMultiple"/>
                </span>
              </th>
              <slot name="thead"></slot>
              <th
                v-if="hasExpadableData"
                class="td-check">
              </th>
            </tr>
          </thead>
          <template v-if="loading">
            <transition name="fade" mode="out-in">
            <tbody class="tbody-loading" :key="loading">
              <tr v-for="(row, rowindex) in 3" :key="rowindex" class="tr-loading">
                <td :key="index" v-for="(col, index) in columns">
                  <div class="skeleton">
                    <div class="line" style="width: 70px;"></div>
                  </div>
                </td>
                <td align="right" class="flex flex-grow flex-wrap action">
                  <div class="skeleton">
                    <div class="line"></div>
                  </div>
                </td>
              </tr>
            </tbody>
            </transition>
          </template>
          <template v-else>
            <transition name="fade" mode="out-in">
            <slot :data="datax"></slot>
            </transition>
          </template>
        </table>
      </div>
      <div class="vx-row w-full flex flex-centered items-center justify-center">
      <div v-if="!loading && data.length===0" class="card-block" style="">
        <feather-icon icon="AlertTriangleIcon" svgClasses="h-4 w-4" />
        <strong>No data</strong>
        <p class="mb-0"> {{ noDataText }}</p>
      </div>
      </div>
      <!-- <div
        v-if="isNoData"
        class="vx-row w-full not-data-table vs-table--not-data flex flex-centered items-center justify-center">
          <div class="vx-col w-full ">
             <feather-icon icon="AlertTriangleIcon" svgClasses="h-4 w-4" />
          </div>
          <div class="vx-col w-full ">
              {{ noDataText }}
          </div>
      </div> -->

      <div
        v-if="pagination"
        class="con-pagination-table vs-table--pagination">
        <vs-pagination
          :total="searchx && !sst ? getTotalPagesSearch : getTotalPages"
          v-model="currentx"></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
 import {debounce} from '../../helpers'
export default {
  name: "VxTable",
  props: {
    value: {},
    columns:{
      default: 5,
      type:Number
    },
    color: {
      default: "primary",
      type: String
    },
    noDataText: {
      default: "looks like we don't have any data to show",
      type: String
    },
    stripe: {
      default: false,
      type: Boolean
    },
    hoverFlat: {
      default: false,
      type: Boolean
    },
    maxHeight: {
      default: "auto",
      type: String
    },
    multiple: {
      default: false,
      type: Boolean
    },
    data: {
      default: null
    },
    notSpacer: {
      default: false,
      type: Boolean
    },
    search: {
      default: false,
      type: Boolean
    },
    maxItems: {
      default: 5,
      type: [Number, String]
    },
    pagination: {
      default: false,
      type: Boolean
    },
    currentPage: {
      default: 1,
      type: Number | String
    },
    sst: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    headerWidth: "100%",
    trs: [],
    datax: [],
    searchx: null,
    currentx: 1,
    hasExpadableData: false,
    isOpen:false
  }),
  computed: {
    getTotalPages() {
      return Math.ceil(this.data.length / this.maxItems);
    },
    getTotalPagesSearch() {
      let dataBase = this.data;

      let filterx = dataBase.filter(tr => {
        let values = this.getValues(tr)
          .toString()
          .toLowerCase();
        return values.indexOf(this.searchx.toLowerCase()) != -1;
      });

      return Math.ceil(filterx.length / this.maxItems);
    },
    isNoData() {
      if (typeof this.datax == Object) {
        return this.datax
          ? Object.keys(this.datax).length == 0
          : false && this.search;
      } else {
        return this.datax ? this.datax.length == 0 : false && this.search;
      }
    },
    isCheckedLine() {
      let lengthx = this.data.length;
      let lengthSelected = this.value.length;
      return lengthx !== lengthSelected;
    },
    isCheckedMultiple() {
      return this.value.length > 0;
    },
    styleConTbody() {
      return {
        maxHeight: this.maxHeight,
        overflow: this.maxHeight != "auto" ? "auto" : null
      };
    },
    getThs() {
      let ths = this.$slots.thead.filter(item => item.tag);
      return ths.length;
    },
    tableHeaderStyle() {
      return {
        width: this.headerWidth
      };
    }
  },
  watch: {
    currentPage() {
      this.currentx = this.currentPage;
    },
    currentx() {
      if (this.sst) {
        this.$emit("change-page", this.currentx);
      } else {
        this.loadData();
      }
    },
    maxItems() {
      this.loadData();
    },
    data() {
      // console.log(this.data)
      this.loadData();
      this.currentx = 1;
      this.$nextTick(() => {
        if (this.datax.length > 0) {
          this.changeTdsWidth();
        }
      });
    },
    searchx:debounce(function () {
       this.$emit("searched", this.searchx);
    }, 500)
    // () {
    //   if (this.sst) {
    //     this.$emit("searched", this.searchx);
    //   } else {
    //     this.loadData();
    //     this.currentx = 1;
    //   }
    // }
  },
  mounted() {
    window.addEventListener("resize", this.listenerChangeWidth);

    this.loadData();

    // this.$nextTick(() => {
    //   if(this.datax.length > 0) {
    //     this.changeTdsWidth()
    //   }
    // })
  },
  destroyed() {
    window.removeEventListener("resize", this.listenerChangeWidth);
  },
  methods: {
    loadData() {
      let max = Math.ceil(this.currentx * this.maxItems);
      let min = max - this.maxItems;
      if (!this.searchx) {
        this.datax = this.pagination
          ? this.getItems(min, max)
          : this.data || [];
      } else {
        this.datax = this.pagination
          ? this.getItemsSearch(true, min, max)
          : this.getItemsSearch(false, min, max) || [];
      }
    },
    getItems(min, max) {
      let items = [];

      this.data.forEach((item, index) => {
        if (index >= min && index < max) {
          items.push(item);
        }
      });
      return items;
    },
    getItemsSearch(min, max) {
      let dataBase = this.data;

      let filterx = dataBase.filter(tr => {
        let values = this.getValues(tr)
          .toString()
          .toLowerCase();
        return values.indexOf(this.searchx.toLowerCase()) != -1;
      });

      let items = [];

      filterx.forEach((item, index) => {
        if (index >= min && index < max) {
          items.push(item);
        }
      });

      return items;
    },
    sort(key, active) {
      if (this.sst) {
        this.$emit("sort", key, active);
        return;
      }
      let datax = this.pagination ? this.data : this.datax;

      function compare(a, b) {
        if (a[key] < b[key]) return active ? 1 : -1;
        if (a[key] > b[key]) return active ? -1 : 1;
        return 0;
      }

      this.datax = datax.sort(compare);
    },
    getValues(obj) {
      let valuesx = Object.values(obj);
      let strings = [];

      function getStrings(obj) {
        if (Array.isArray(obj)) {
          strings = [...strings, ...obj];
          obj.forEach(item => {
            getStrings(item);
          });
        } else if (typeof obj == "object" && obj != null) {
          let subObj = Object.values(obj);
          strings = [...strings, ...subObj];
          getStrings(subObj);
        }
      }
      getStrings(valuesx);

      strings = strings.filter(
        item => typeof item == "string" || typeof item == "number"
      );

      return valuesx;
    },
    getStrings(obj, valuesx) {
      let stringsx = Object.values(obj);
      valuesx.forEach(item => {
        if (item && typeof item == "object") {
          valuesx = [...valuesx, ...Object.values(item)];
        }
      });
      // return [...valuesx,...Object.values(item)]
      return stringsx;
    },
    changeCheckedMultiple() {
      let lengthx = this.data.length;
      let lengthSelected = this.value.length;
      let selectedx = lengthx - lengthSelected;
      //console.log('lengthSelected__',lengthx,selectedx)
      if (selectedx == 0) {
        this.$emit("input", []);
      } else {
        this.$emit("input", this.data);
      }
    },
    clicktr(tr, isTr) {
      if (this.multiple && isTr) {
        let val = this.value.slice(0);
        if (val.includes(tr)) {
          val.splice(val.indexOf(tr), 1);
        } else {
          val.push(tr);
        }

        this.$emit("input", val);
        this.$emit("selected", tr);
      } else if (isTr) {
        this.$emit("input", tr);
        this.$emit("selected", tr);
      }
    },
    listenerChangeWidth() {
      this.headerWidth = `${this.$refs.table.offsetWidth}px`;
      this.changeTdsWidth();
    },
    changeTdsWidth() {
      if (!this.value) return;

      let tbody = this.$refs.table.querySelector("tbody");

      // Adding condition removes querySelector none error - if tbody isnot present
      if (tbody && tbody.querySelector(".tr-values")) {
        let tds = tbody.querySelector(".tr-values").querySelectorAll(".td");
        let tdsx = [];

        tds.forEach((td, index) => {
          tdsx.push({ index: index, widthx: td.offsetWidth });
        });

        let colgrouptable = this.$refs.colgrouptable;
        if (colgrouptable) {
          let colsTable = colgrouptable.querySelectorAll(".col");
          colsTable.forEach((col, index) => {
            col.setAttribute("width", tdsx[index].widthx);
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  // .vs-con-dropdown {
  //  .shadow-drop{
  //   border: 1px solid rgb(42, 65, 232) !important;
  //   color: rgb(42, 65, 232)  !important;
  //  }

  // }
  .header-table {
    // button {
    //   color: #336594;
    // }
  }
  .loading-btn {
    position: relative;
  }
  .con-vs-loading {
    position: absolute !important;
  }
  .vs-con-table {
    .vs-table--header {
      display: block;

      // flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      @media (max-width: 992px) {
        & > .flex-wrap {
          & > .flex-wrap {
            & > div {
              margin: 10px 0;
            }
          }
        }
      }
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;
        flex: 0 1 100%;
        @media (max-width: 992px) {
          max-width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 0.9rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 0.6rem;
      // padding: 0 1rem;
      @media (max-width: 992px) {
        display: flex;
        flex-flow: column;
        min-width: inherit;
      }
      tbody {
        tr {
          @media (max-width: 992px) {
            display: flex !important;
            flex-grow: 1 !important;
            flex-wrap: wrap !important;
            & > * {
              flex: 1;
            }
            margin-bottom: 16px;
            td{
              border-left: none !important;
              border-bottom: none;
            }
          }
          &:hover {
            box-shadow: 0px 10px 50px rgba(255, 255, 255, 0.5),
              0px 10px 50px rgba(0, 0, 0, 0.15) !important;
          }
        }
      }
      tr {
        // box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        // border-radius: 5px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid #e5e5e5;

        td {
          padding: 20px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          &:first-child {
            border-left: 1px solid #e5e5e5;
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-right: 1px solid #e5e5e5;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        border: none;
        @media (max-width: 992px) {
          min-width: inherit;
          display: flex;
          flex-flow: wrap;
          & > * {
            flex: 1;
          }
          margin-bottom: 16px;
        }
        height: 40px;
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  .card-block {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 25px 41px;
    // background-color: #f5f5f5;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
  }
  .expand-packages {
    @media (max-width: 992px) {
      .vx-card {
        max-width: inherit !important;
      }
    }
    & > div {
      flex: 1 1 44%;
      @media (min-width: 992px) {
        &:first-of-type {
          margin-right: 40px;
        }
      }
    }
  }
}

table .skeleton {
	height: inherit;
}
table .skeleton .line {
	background-image: linear-gradient(
		90deg,
		#f8f8f8 0px,
		#e8e8e8 40px,
		#f8f8f8 80px
	) !important;
}
.tbody-loading{
  margin-bottom: 20;
}
 table .tr-loading {
   @media (max-width: 992px) {
    margin: 10px 0;
   }
   td{
      padding: 30px !important;
      @media (max-width: 992px) {
        border-left: none !important;
        border-bottom: none !important;
        border-top: none !important;
      }
    }
  }

/*  skeleton Loading */
.skeleton {
	height: 80px;
	display: flex;
	flex-flow: column;
}

.skeleton .line {
	width: 120px;
	height: 16px;
	margin-top: 12px;
	border-radius: 7px;
	background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	animation: shine-lines 1.6s infinite linear;
}
.skeleton .line.white{
	background-image: linear-gradient(90deg, #fff 0px, #eceff7 40px, #fff 80px);
}
.skeleton .line.none {
	background-image: none;
}

.skeleton .avatar + .line {
	margin-top: 11px;
	width: 100px;
}
.skeleton .line ~ .line {
	background-color: #ddd;
}

@keyframes shine-lines {
	0% {
		background-position: -100px;
	}
	40%,
	100% {
		background-position: 140px;
	}
}
@keyframes shine-avatar {
	0% {
		background-position: -32px;
	}
	40%,
	100% {
		background-position: 208px;
	}
}
</style>
